/* eslint-disable import/no-anonymous-default-export */
import React, { useState } from 'react';
import Api from 'wever-maps/dist/services/api';

import favicon from '../asserts/favicon.png';

export default (props) => {
    const { onConnect } = props;
    const [email, setEmail] = useState();
    const [token, setToken] = useState();
    const [mapid, setMapId] = useState();
    const [reportId, setReportId] = useState();
    const [isLoading, setLoading] = useState(false);

    const handleConnect = async () => {
        setLoading(true);
        const response = await Api.post('/auth/partner', {
            email,
            token
        });
        setLoading(false);
        if (onConnect && typeof onConnect === 'function') {
            onConnect(response.data, mapid, reportId);
        }
    }

    return (
    <div style={{
        margin: 'auto', 
        marginTop: '10%', 
        background: '#fafafa', 
        border: '1px solid #ccc', 
        width: '400px',
        padding: '10px'
        }}>
        <div style={{width: '100%', textAlign: 'center'}}>
            <span><img src={favicon} alt="icon" height="30px" /></span>
            <span>{isLoading ? 'En chargement ... ' : <h5> Maps </h5>}</span>
        </div>
        <div>
            <label>
                <span style={{width: '50px'}}>Map ID: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <input type="number" value={mapid} onChange={(e) => setMapId(e.target.value)} />
            </label>
        </div>
        <div>
            <label>
                <span style={{width: '50px'}}>Report ID (admin): &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <input type="number" value={reportId} onChange={(e) => setReportId(e.target.value)} />
            </label>
        </div>
        <div>
            <label>
                <span style={{width: '50px'}}>email: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
            </label>
        </div>
        <div>
            <label>
                <span style={{width: '50px'}}>clé partenaire: </span>
                <input type="text" value={token} onChange={(e) => setToken(e.target.value)} />
            </label>
        </div>
        <hr/>
        <button type="button" onClick={handleConnect} disabled={isLoading}>Connecter</button>
    </div>
    );
}