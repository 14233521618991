import React, { useState } from 'react';
import Login from './components/login';
import WeverMaps from 'wever-maps';

import 'wever-maps/dist/styles/bootstrap.css';
import './index.css';

function App() {
  const [token , setToken] = useState();
  const [reportId , setReportId] = useState();
  const [mapId , setMapid] = useState();

  const handleConnection = (values, mapidChoice, reportId) => {
    setToken(values.token);
    setReportId(reportId || values.reportId);
    setMapid(mapidChoice);
  }

  if (!token) {
    return <Login onConnect={handleConnection} />;
  }

  if (!reportId) {
    return <div>Aucun report id, rechargez la page.</div>
  }

  return (
    <div className="App">
      <WeverMaps lang="fr" token={token} reportId={reportId} mapId={mapId} />
    </div>
  );
}

export default App;
